import React, { forwardRef } from 'react';
import './Cards.css';

const Cards = forwardRef(function Cards(props, ref) {
  return (
    <div className='cards' ref={ref}>
      <h1>Áreas de Atuação</h1>
      
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <div class="button-group">
            <div class="group-label">
                <i class="fas fa-scale-balanced group-icon"></i>
                <div class="button-description">Direto Civil</div>
            </div>
            <div class="buttons">
                <div class="button-container">
                    <button class="button-icon"><i class="fas fa-file-invoice"></i></button>
                    <div class="button-description">Contratos</div>
                </div>
                <div class="button-container">
                    <button class="button-icon"><i class="fas fa-file-signature"></i></button>
                    <div class="button-description">Sucessões</div>
                </div>
                <div class="button-container">
                    <button class="button-icon"><i class="fas fa-gift"></i></button>
                    <div class="button-description">Consumidor</div>
                </div>
                <div class="button-container">
                    <button class="button-icon"><i class="fas fa-home"></i></button>
                    <div class="button-description">Imobiliário</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Cards;