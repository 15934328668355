import React from 'react';
import './ArticleDetail.css';
import ArticleCard from './ArticleCard';

const ArticleDetail = ({article, articles}) => {
  return (
    <div className='article'>
      <div className='article_container'>
        <div className='article_wrapper'>
          <div className='article_img_container'>
            <div className='article_img'></div>
          </div>
          <div className='article_info'>
            <h1 className='article_title'>{article.title}</h1>
                <div className='article_text' dangerouslySetInnerHTML={{__html: article.text}}></div>
          </div>
          <div className='article_others'>
            <h3>Veja também outros artigos</h3>
              <div className='articles__wrapper'>
                <ul className='articles__items'>
                    {
                        articles.map(artigo =>
                            <ArticleCard
                                id={artigo.id}
                                src={'../' + artigo.image}
                                label={artigo.title}
                                text={artigo.text.substring(0, 130) + "..."}
                                path={"/article/" + artigo.id}
                            />
                        )
                    }
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleDetail;