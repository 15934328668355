import React from 'react';
import '../../App.css';
import Cards from '../Cards/Cards';
import FirstSection from '../FirstSection/FirstSection';
import AboutSection from '../AboutSection/AboutSection';
import ArticleSection from '../ArticleSection/ArticleSection';

const Home = ({ sections, scrollToSection, articles }) => {

  return (
    <>
      <FirstSection scrollToSection={scrollToSection} sections={sections} ref={sections.firstSection} />
      <AboutSection ref={sections.aboutSection} />
      <Cards ref={sections.cardsSection} />
      <ArticleSection ref={sections.articleSection} articles={articles} />
    </>
  );
}

export default Home;