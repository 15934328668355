import React, {useRef} from 'react';
import Navbar from './components/Navbar/Navbar'
import './App.css';
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Footer from './components/Footer/Footer';
import Whatsapp from './components/Whatsapp/Whatsapp';
import Article from './components/pages/Article';
import Services from './components/pages/Services';
import About from './components/pages/About';

function App() {
  const firstSection = useRef();
  const aboutSection = useRef();
  const cardsSection = useRef();
  const articleSection = useRef();
  const navbarSection = useRef();
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    switch (section) {
      case firstSection:
        if (firstSection.current){
          firstSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else{
          window.location.href = '/';
        }
        break;
      case aboutSection:
        if (aboutSection.current){
          aboutSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else{
          window.location.href = '/about';
        }
        break;
      case cardsSection:
        if (cardsSection.current){
          cardsSection.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        else{
          window.location.href = '/services';
        }
        break;
      case articleSection:
        if (articleSection.current){
          articleSection.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        else{
          window.location.href = '/articles';
        }
        break;
      case navbarSection:
        if (navbarSection.current){
          navbarSection.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        else{
          window.location.href = '/';
        }
        break;
      default:
        break;
    }
  };

  const sections = { firstSection, aboutSection, cardsSection, articleSection, navbarSection };

  const artigos = [
    { 
        'id': 1, 
        'title': 'UNIÃO ESTÁVEL, QUAL REGIME DE BENS?', 
        'text': '<p>Quando os conviventes nada convencionam acerca de um regime de bens que regulam aquela união estável, estes ficarão sujeitos ao regime da comunhão parcial de bens, entretanto, os assim como os nubentes, os conviventes também têm a liberdade optar por outro regime via contrato de convivência, que nada mais é do que um pacto antenupcial, com acentuadas vantagens.<br/>A regra é que no silêncio dos conviventes é que se aplica o regime da comunhão parcial, conforme preceitua o artigo 1.725 do Código Civil.<br/>Portanto, em que pese na união estável vigorar o regime da comunhão parcial de bens, é plena a liberdade dos companheiros para estabelecerem, em contrato escrito, o que desejarem, definindo, da forma que melhor lhes aprouver, o destino dos bens passados, presentes e futuros.<br/>Desta forma, é possível afirmar que a inércia dos conviventes configura uma manifestação de vontade, de modo que, apesar de não estarem obrigados os conviventes a definir o regime de bens antes da vida em comum, se eles não desejarem o regime da comunhão parcial, necessariamente terão que formalizar um contrato de convivência.</p>', 
        'image': 'images/artigo1.jpg'
    },
    {
        'id': 2, 
        'title': 'É POSSÍVEL ALTERAR O REGIME DE BENS DURANTE O CASAMENTO?', 
        'text': '<p>O regime de bens é escolhido livremente pelos noivos, antes do casamento. Todavia, durante o casamento, os cônjuges podem alterar o regime de bens, desde que de comum acordo e mediante apresentação de uma justificativa.<br />Não é possível realizar a mudança do regime de bens na esfera extrajudicial. A alteração do regime de bens é um ato judicial e depende da propositura de ação judicial por ambos os cônjuges de forma consensual e perante a vara da família e deve haver a intervenção do Ministério Público, nos termos do art. 734, § 1º do CPC.<br />Destaca-se que o casal, representado por advogado, precisam justificar o motivo pelo qual desejam a troca do regime e, caso o juiz entenda necessário, poderá determinar a produção de provas.<br />Muito se discute acerca da justificativa para embasar a troca e, através da jurisprudência, podemos indicar que as justificativas predominantemente aceitas pelo poder Judiciário para este fim são as seguintes: atividade empresarial, independência financeira e estabilidade familiar.<br />A possibilidade desta alteração encontra guarida no Enunciado 113 do CJF que assim dispõe:<br /><b>ENUNCIADO 113</b> – <i>“É admissível a alteração do regime de bens entre os cônjuges, quando então o pedido, devidamente motivado e assinado por ambos os cônjuges, será objeto de autorização judicial, com ressalva dos direitos de terceiros, inclusive dos entes públicos, após perquirição de inexistência de dívida de qualquer natureza, exigida ampla publicidade.”</i><br />Por fim, insta consignar que não é admitida a alteração do regime de bens quando o regime de separação foi imposto em face da idade – quando um ou ambos os noivos haviam idade superior a 70 anos quando se casaram – art. 1.641, I, do Código Civil.</p>', 
        'image': 'images/artigo2.jpg'
    },
    {
        'id': 3, 
        'title': 'NO DIVÓRCIO PRECISO DIVIDIR MEU FTGS?', 
        'text': '<p>O Fundo de Garantia do Tempo de Serviço é um fundo criado com o objetivo de proteger o trabalhador que for demitido sem justa causa.<br />Os valores percebidos a este título por qualquer dos cônjuges ou companheiros na vigência da vida comum compõem o patrimônio do casal e, portanto, é objeto de partilha por ocasião da separação, assim como os créditos trabalhistas percebidos durante a vigência da vida conjugal.<br />Tais verbas, se adquiridas na vigência da união, ainda que recebidas depois da separação, devem compor a meação.<br />No caso do FGTS, o juiz deverá expedir comunicação à Caixa Econômica Federal para que esta providencie a reserva da metade do montante acumulado durante o período da vida comum para, em momento futuro, ocorrendo alguma das hipóteses legais de saque, seja possível a retirada do numerário pelo meeiro.</p>', 
        'image': 'images/artigo3.jpg'
    }
  ];

  return (
    <div>
      <Navbar scrollToSection={scrollToSection} sections={sections} />
      <Routes>
          <Route element={ <Home sections={sections} scrollToSection={scrollToSection} articles={artigos} /> }  path="/" exact />
          <Route element={ <Contact /> }  path="/contact" />
          <Route element={ <Article articles={artigos} sections={sections} /> }  path="/articles" />
          <Route element={ <Article articles={artigos} sections={sections}/> }  path="/article/:id" />
          <Route element={ <Services /> }  path="/services" />
          <Route element={ <About /> }  path="/about" />
      </Routes>
      <Footer />
      <Whatsapp />
    </div>
  );
}

export default App;
