import React, { forwardRef } from 'react';
import './AboutSection.css';

const AboutSection = forwardRef(function AboutSection( props, ref ) {
  return (
    <div className='about' id='about' ref={ref}>
      <div className='about_container'>
        <div className='about_wrapper'>
          <div className='about_img_container'>
            <div className='about_img'></div>
          </div>
          <div className='about_info'>
            <h1 className='about_title'>Sobre mim</h1>
            <p className='about_text'>Olá! Seja bem-vindo(a)!<br/>Me chamo Letícia Semensato Giusti, sou advogada formada pela Pontifícia Universidade Católica do Paraná e possuo inscrição na OAB do Estado do Paraná. Me especializei em direito do trabalho e também em direito imobiliário, registral e notarial pela PUC-PR.<br />Atuo na área do Direito desde 2014, ano que iniciei meu primeiro estágio, portanto, somo mais de 10 anos de experiência, sendo os cinco últimos dedicados ao exercício da advocacia de forma ininterrupta. Atualmente tenho a honra de trabalhar na Advocacia Schmitz, escritório com sede em Curitiba onde atuo com profissionais de excelência e seriedade nas mais diversas áreas do direito. Se você procura profissionalismo, competência e informações seguras sobre os seus direitos, você está no lugar certo! Conte comigo!!</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AboutSection;