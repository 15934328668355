import React from 'react';
import './ContactSection.css';
import MapContainer from '../MapContainer/MapContainer';

function ContactSection() {
  return (
    <div className='contact'>
      <h1>Entre em Contato</h1>
      <div className='contact__container'>
        <div className='contact__info'>
          <p><i className='fab fa-whatsapp' />&nbsp;(41) 99642-3042</p>
          <p>leticia@advocaciaschmitz.com.br</p>
          <p>Travessa Itararé, 181, Cj. 4, Centro - Curitiba/PR</p>
        </div>
        <div className='contact__map'>
          <MapContainer />
        </div>
      </div>
    </div>
  );
}

export default ContactSection;