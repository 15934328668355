import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './MapContainer.css';

class MapContainer extends Component {
    render() {
        const mapStyles = {
            width: '100%',
            height: '100%'
        };

        return (
            <div className='map-container'>
                <Map
                    google={this.props.google}
                    zoom={14}
                    style={mapStyles}
                    initialCenter={{
                        lat: -25.431300379353072,
                        lng: -49.26238522612547
                    }}
                >
                    <Marker
                        position={{
                            lat: -25.431300379353072,
                            lng: -49.26238522612547
                        }}
                    />
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDxQotB2sDc7Ce412GJ_sEbEVdmHub5Z2o'
})(MapContainer);
