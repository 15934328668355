import React, { forwardRef } from 'react';
import './ArticleSection.css';
import ArticleCard from './ArticleCard';

const ArticleSection = forwardRef(function ArticleSection(props, ref) {
    
    return (
        <div className='articles' ref={ref}>
            <h1>Artigos</h1>
            <div className='articles__container'>
                <div className='articles__wrapper'>
                    <ul className='articles__items'>
                        {
                            props.articles.map(artigo =>
                                <ArticleCard
                                    key={artigo.id}
                                    id={artigo.id}
                                    src={artigo.image}
                                    label={artigo.title}
                                    text={artigo.text.substring(0, 130) + "..."}
                                    path={"/article/" + artigo.id}
                                />
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
});

export default ArticleSection;