import React, { useState, useEffect, forwardRef } from 'react';
import { Button } from '../Button/Button';
import './FirstSection.css';

const FirstSection = forwardRef(function FirstSection(props, ref) {
  const [mostrarDiv, setMostrarDiv] = useState(false);
  useEffect(() => {
    // Após um atraso de 1 segundo (1000 milissegundos), define mostrarDiv como true
    const timeout = setTimeout(() => {
      setMostrarDiv(true);
    }, 100);

    return () => clearTimeout(timeout); // Limpa o timeout quando o componente é desmontado
  }, []);
  return (
    <div className={`first-container ${mostrarDiv ? 'mostrar' : ''}`} ref={ref}>
      <div className='first-container-right'>
        <h1>Letícia Giusti</h1>
        <p>Advocacia e Consultoria Jurídica</p>
        <div className='first-btns'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={() => props.scrollToSection(props.sections.aboutSection)}
          >
            Conheça Mais
          </Button>
        </div>
      </div>
    </div>
  );
});

export default FirstSection;