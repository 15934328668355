import React from 'react';
import '../../App.css';
import ArticleDetail from '../ArticleSection/ArticleDetail';
import { useParams } from 'react-router-dom';
import ArticleSection from '../ArticleSection/ArticleSection';

const Article = ({ articles, sections }) => {
  let { id } = useParams();
  var article = null;
  var articles_array = Array();
  let link;

  if(id){
    article = articles.find((a) => 
      a.id == id
    );
    articles_array = articles.filter((a) => 
      a.id != id
    );

    if(article){
      link = <ArticleDetail article={article} articles={articles_array} />
    }
    else{
      window.location.href = '/';
    }
  }
  else{
    link = <ArticleSection ref={sections.articleSection} articles={articles} />
  }

  return <div>
    {link}
  </div>;
}

export default Article;