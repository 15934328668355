import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import LogoIcon from '../LogoIcon/LogoIcon';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <LogoIcon />
              <p>Letícia Giusti</p>
            </Link>
          </div>
          <div className='footer-info'>
            <h1>Informações</h1>
            <p><i className='fab fa-whatsapp' />&nbsp;(41) 99642-3042</p>
            <p>leticia@advocaciaschmitz.com.br</p>
            <p>Travessa Itararé, 181, Cj. 4, Centro - Curitiba/PR</p>
          </div>
          <div className='social-icons'>
            <Link
              className='social-icon-link instagram'
              to='https://www.instagram.com/leticiagiusti.adv/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link whatsapp'
              to='https://api.whatsapp.com/send?phone=5541996423042'
              target='_blank'
              aria-label='Whatsapp'
            >
              <i className='fab fa-whatsapp' />
            </Link>
            <Link
              className='social-icon-link linkedin'
              to='https://br.linkedin.com/in/leticiagiustiadv'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;